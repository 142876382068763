<template>
  <v-card class="rounded-xl overflow-hidden mx-auto" width="1000">
    <v-toolbar class="primary" tag="div" flat dark tile>
      <v-toolbar-title> Configurations </v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-tabs v-model="tabSelect" grow show-arrows>
      <!-- <v-tab href="#tab-1">
        <v-icon left>mdi-message-text</v-icon>
        Notifications
      </v-tab> -->
      <v-tab href="#tab-2">
        <v-icon left>mdi-tablet-ipad</v-icon>
        Devices
      </v-tab>
      <v-tab href="#tab-3" v-if="isAdmins">
        <v-icon left>mdi-phone-log</v-icon>
        Phone Numbers
      </v-tab>
      <v-tab href="#tab-4" v-if="isSuper">
        <v-icon left>mdi-code-braces</v-icon>
        ENVIROMENTS
      </v-tab>
      <v-tab href="#tab-5" v-if="isSuper">
        <v-icon left>mdi-cogs</v-icon>
        GENERAL
      </v-tab>
      <v-tab href="#tab-6" v-if="isSuper">
        <v-icon left>mdi-clipboard-text</v-icon>
        DOCUMENTS
      </v-tab>
      <v-tab href="#tab-7" v-if="isSuper">
        <v-icon left>mdi-wrench</v-icon>
        FORM
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabSelect">
      <!--   <v-tab-item value="tab-0" v-if="isSuper">
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="settingTitle"
              label="Title"
              outlined
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn color="primary" @click="saveSettingsGenerals()" dark
              >Save</v-btn
            >
          </v-col>
        </v-row>
      </v-tab-item> -->
      <!--   <v-tab-item value="tab-1">
        <v-container>
          <v-list flat three-line>
            <v-list-item-group>
              <v-list-item>
                <template>
                  <v-list-item-action>
                    <v-checkbox v-model="notify.new.value"></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>New Appointment</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-row class="mx-2">
                        <v-textarea
                          height="10"
                          width="100"
                          solo
                          name="input-7-4"
                          label="message"
                          >Notify me about updates to apps or games that I
                          downloaded
                        </v-textarea>
                        <v-checkbox
                          v-model="notify.new.sms"
                          color="red"
                          hide-details
                        >
                          <template v-slot:label>
                            <div>
                              <v-icon left>mdi-message-text</v-icon>
                              SMS
                            </div>
                          </template>
                        </v-checkbox>
                        <v-checkbox
                          v-model="notify.new.email"
                          color="red darken-3"
                          hide-details
                        >
                          <template v-slot:label>
                            <div>
                              <v-icon left>mdi-gmail</v-icon>
                              EMAIL
                            </div>
                          </template>
                        </v-checkbox>
                      </v-row>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-list-item>

              <v-list-item>
                <template>
                  <v-list-item-action>
                    <v-checkbox v-model="notify.change.value"></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title
                      >Appointment have been modify</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      <v-row class="mx-2">
                        <v-textarea
                          height="10"
                          width="100"
                          solo
                          name="input-7-4"
                          label="message"
                          >Notify me about updates to apps or games that I
                          downloaded
                        </v-textarea>
                        <v-checkbox
                          v-model="notify.change.sms"
                          color="red"
                          hide-details
                        >
                          <template v-slot:label>
                            <div>
                              <v-icon left>mdi-message-text</v-icon>
                              SMS
                            </div>
                          </template>
                        </v-checkbox>
                        <v-checkbox
                          v-model="notify.change.email"
                          color="red darken-3"
                          hide-details
                        >
                          <template v-slot:label>
                            <div>
                              <v-icon left>mdi-gmail</v-icon>
                              EMAIL
                            </div>
                          </template>
                        </v-checkbox>
                      </v-row>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-list-item>

              <v-list-item>
                <template>
                  <v-list-item-action>
                    <v-checkbox v-model="notify.oneDay.value"></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title
                      >His Appointment is tomorrow</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      <v-row class="mx-2">
                        <v-textarea
                          height="10"
                          width="100"
                          solo
                          name="input-7-4"
                          label="message"
                          >Notify me about updates to apps or games that I
                          downloaded
                        </v-textarea>
                        <v-checkbox
                          v-model="notify.oneDay.sms"
                          color="red"
                          hide-details
                        >
                          <template v-slot:label>
                            <div>
                              <v-icon left>mdi-message-text</v-icon>
                              SMS
                            </div>
                          </template>
                        </v-checkbox>
                        <v-checkbox
                          v-model="notify.oneDay.email"
                          color="red darken-3"
                          hide-details
                        >
                          <template v-slot:label>
                            <div>
                              <v-icon left>mdi-gmail</v-icon>
                              EMAIL
                            </div>
                          </template>
                        </v-checkbox>
                      </v-row></v-list-item-subtitle
                    >
                  </v-list-item-content>
                </template>
              </v-list-item>
              <v-list-item>
                <template>
                  <v-list-item-action>
                    <v-checkbox v-model="notify.oneHour.value"></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title
                      >The Appointment is in an hour</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      <v-row class="mx-2">
                        <v-textarea
                          height="10"
                          width="100"
                          solo
                          name="input-7-4"
                          label="message"
                          >Notify me about updates to apps or games that I
                          downloaded
                        </v-textarea>
                        <v-checkbox
                          v-model="notify.oneHour.sms"
                          color="red"
                          hide-details
                        >
                          <template v-slot:label>
                            <div>
                              <v-icon left>mdi-message-text</v-icon>
                              SMS
                            </div>
                          </template>
                        </v-checkbox>
                        <v-checkbox
                          v-model="notify.oneHour.email"
                          color="red darken-3"
                          hide-details
                        >
                          <template v-slot:label>
                            <div>
                              <v-icon left>mdi-gmail</v-icon>
                              EMAIL
                            </div>
                          </template>
                        </v-checkbox>
                      </v-row></v-list-item-subtitle
                    >
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-layout row wrap justify-end mx-4 my-2>
            <v-flex shrink>
              <v-btn color="primary" dark @click="saveSettings">Save</v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-tab-item> -->
      <!-- Devices -->
      <v-tab-item value="tab-2">
        <template>
          <div class="d-flex align-end">
            <v-dialog v-model="CreateDialog" persistent max-width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-2 my-1"
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  Add+
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">Create Device</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          v-model="deviceName"
                          label="Device Name"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          v-model="type"
                          :items="['PROCEDURE', 'DOOR']"
                          label="Device Type"
                          required
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="cancel">
                    Close
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="createDevice">
                    Create
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-spacer></v-spacer>
            <div class="col-sm-4">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </div>
          </div>
          <!-- Create Devices -->

          <!--  All Devices -->
          <v-data-table
            :loading="loadingDevice"
            dense
            :search="search"
            :items="devices"
            :headers="headers"
            item-key="uuid"
            class="elevation-1"
          >
            <template class="tooltip top" v-slot:[`item.deviceName`]="props">
              <!--  <span class="tiptext"> Click to Edit </span> -->
              {{ props.item.deviceName }}
            </template>
            <template class="tooltip top" v-slot:[`item.link`]="props">
              <!--  <span class="tiptext"> Click to Edit </span> -->
              <v-badge :color="props.item.link == null ? 'green' : 'red'" dot>
              </v-badge>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-menu
                bottom
                origin="center center"
                rounded="lg"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <!--  Button Remove -->
                  <v-list-item link>
                    <v-dialog
                      v-model="dialogConfirmRemove"
                      max-width="280"
                      content-class="rounded-xl"
                    >
                      <template v-slot:activator="{ on: dialog, attrs }">
                        <v-btn color="red" v-on="{ ...dialog }" v-bind="attrs">
                          <v-icon small left>mdi-delete</v-icon>Remove
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title
                          class="text-h6 font-weight-regular rounded-xl"
                        >
                          {{ $t("dialogTitle") }}
                        </v-card-title>

                        <v-card-text column>
                          <div class="text-body-1 text-truncate d-flex mb-1">
                            {{ item.deviceName }}
                          </div>
                        </v-card-text>
                        <v-divider />

                        <v-card-actions class="justify-end">
                          <v-btn
                            color="success"
                            :disabled="loading"
                            width="100"
                            text
                            rounded
                            @click="cancel()"
                          >
                            {{ $t("labelCancel") }}
                          </v-btn>
                          <v-btn
                            color="red"
                            :loading="loading"
                            @click="remove(item.uuid)"
                            width="100"
                            depressed
                            rounded
                            dark
                          >
                            {{ $t("labelRemove") }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-list-item>

                  <!-- Button Unlock -->
                  <v-list-item v-if="item.link != null" link>
                    <v-dialog
                      v-model="dialogConfirmUnlock"
                      max-width="280"
                      content-class="rounded-xl"
                    >
                      <template v-slot:activator="{ on: dialog, attrs }">
                        <v-btn
                          color="warning"
                          v-on="{ ...dialog }"
                          v-bind="attrs"
                        >
                          <v-icon small left>mdi-lock-open</v-icon>unlock
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title
                          class="text-h6 font-weight-regular rounded-xl"
                        >
                          Unlock
                        </v-card-title>

                        <v-card-text column>
                          <div class="text-body-1 text-truncate d-flex mb-1">
                            {{ item.deviceName }}
                          </div>
                        </v-card-text>
                        <v-divider />

                        <v-card-actions class="justify-end">
                          <v-btn
                            color="success"
                            :disabled="loading"
                            width="100"
                            text
                            rounded
                            @click="cancel()"
                          >
                            {{ $t("labelCancel") }}
                          </v-btn>
                          <v-btn
                            color="warning"
                            :loading="loading"
                            @click="unlock(item.uuid)"
                            width="100"
                            depressed
                            rounded
                            dark
                          >
                            {{ $t("labelUnlock") }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-list-item>

                  <!--  Boton Edit  -->
                  <v-list-item link>
                    <v-dialog v-model="DialogEdit" temporary max-width="400">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="success"
                          width="120"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon small left>mdi-qrcode</v-icon>
                          Qr
                        </v-btn>
                      </template>
                      <template v-if="item.type == 'DOOR'">
                        <qr-code
                          :size="400"
                          error-level="L"
                          :text="
                            JSON.stringify({
                              apiBaseUrl: apiurl,
                              access_token: item.access_token,
                            })
                          "
                        ></qr-code>
                      </template>
                      <template v-else>
                        <qr-code
                          :size="400"
                          error-level="L"
                          :text="
                            JSON.stringify({
                              uuid: item.uuid,
                              name: item.deviceName,
                            })
                          "
                        ></qr-code>
                      </template>
                    </v-dialog>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </template>
      </v-tab-item>
      <v-tab-item value="tab-3" v-if="isAdmins">
        <phone-numbers />
      </v-tab-item>
      <v-tab-item value="tab-4" v-if="isSuper">
        <env-settings></env-settings>
      </v-tab-item>
      <v-tab-item value="tab-5" v-if="isSuper">
        <crm-config></crm-config>
      </v-tab-item>
      <v-tab-item value="tab-6" v-if="isSuper">
        <documents-to-send />
      </v-tab-item>
      <v-tab-item value="tab-7" v-if="isSuper">
        <config-form-view />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { apiBaseUrl } from "@/enviorment";
import rules from "@/components/account/rules";
import store from "@/store/index";
import { mapActions, mapState } from "vuex";
import VueQRCodeComponent from "vue-qr-generator";
import ENVIROMENTS from "../Generals/ENVIROMENT.vue";
import CrmGeneralConfig from "../Generals/CrmGeneralConfig.vue";
import PhoneNumbers from "@/views/Configurations/Generals/PhoneNumbers.vue";
import DocumentsToSend from "../Generals/DocumentsToSend.vue";

import ConfigFormView from "@/views/Configurations/Generals/ConfigFormView.vue";
export default {
  components: {
    "qr-code": VueQRCodeComponent,
    "env-settings": ENVIROMENTS,
    "crm-config": CrmGeneralConfig,
    PhoneNumbers,
    DocumentsToSend,

    ConfigFormView,
  },
  data: () => ({
    search: "",
    settingTitle: "",

    tabSelect: null,
    dialogConfirmRemove: false,
    dialogConfirmUnlock: false,
    loading: false,
    isAdmins: false,
    isSuper: false,
    DialogEdit: false,

    CreateDialog: false,
    title: "",
    notify: {
      new: {
        value: false,
        sms: false,
        email: false,
      },
      change: {
        value: false,
        sms: false,
        email: false,
      },
      oneDay: {
        value: false,
        sms: false,
        email: false,
      },
      oneHour: {
        value: false,
        sms: false,
        email: false,
      },
    },
    rules,

    rule: [(v) => v.length <= 125 || "Max 125 characters"],
    tabs: [
      {
        title: "Via Sms",
        icon: "mdi-message-text",
      },
      {
        title: "Via email",
        icon: "mdi-email",
      },
    ],

    headers: [
      {
        text: "NAME",
        align: "start",
        sortable: false,
        value: "deviceName",
      },
      { text: "AVAILABLE", value: "link", sortable: false },
      { text: "TYPE", value: "type", sortable: false },
      { text: "", value: "actions", sortable: false },
    ],
    deviceName: "",
    type: "",
  }),
  computed: {
    ...mapState("crmConfigurationsModule", ["devices", "loadingDevice"]),

    apiurl() {
      return apiBaseUrl;
    },
  },
  methods: {
    ...mapActions("crmConfigurationsModule", [
      "actGetDevices",
      "actDeviceCreate",
      "actDeviceRemove",
      "actDeviceUnlock",
    ]),

    saveSettings() {
      console.log(this.notify);
    },
    saveSettingsGenerals() {
      this.$store.dispatch("actAddSettings", {
        name: "title",
        value: this.settingTitle,
      });
    },
    cancel() {
      this.dialogConfirmRemove = false;
      this.dialogConfirmUnlock = false;
      this.loading = false;
      this.DialogEdit = false;
      this.CreateDialog = false;
      this.deviceName = "";
      this.type = "";
    },
    async remove(uuid) {
      await this.actDeviceRemove(uuid);
      this.loading = false;
    },
    async unlock(uuid) {
      await this.actDeviceUnlock(uuid);
      this.dialogConfirmUnlock = false;
      this.loading = false;
    },
    async createDevice() {
      await this.actDeviceCreate({
        deviceName: this.deviceName,
        type: this.type,
      });
      this.cancel();
    },
  },
  async mounted() {
    this.settingTitle = store.state.dataCRM.title;

    await this.actGetDevices();
    if (store.getters.isAdmin || store.getters.isSuper) {
      this.isAdmins = true;
    }
    if (store.getters.isSuper) {
      this.isSuper = true;
    }
  },
};
</script>
<i18n>
{
  "en": {
    "dialogTitle": "Remove",
    "notifyRemove": "Removed successfully"
  },
  "es": {
    "dialogTitle": "Eliminar",
    "notifyRemove": "Eliminado con éxito"
  }
}
</i18n>
